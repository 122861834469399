import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const FeedbackThanksPage = () => (
  <Layout bodyClassName='research'>
    <SEO
      title="Thanks!"
      meta={[
        {
          property: `robots`,
          content: `noindex`,
        },
      ]}
    />

    <div className="page">
      <div className="container">
        <h1 className="pageTitle">Thanks for your help <span role="img" aria-label="thank you">🙏🏼</span></h1>
      </div>
    </div>
  </Layout>
);

export default FeedbackThanksPage
